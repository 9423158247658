<template>
  <v-app>
    <v-app-bar elevation="3" app color="white" height="96px">
      <v-container class="d-flex align-center">
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              max-height="76px"
              max-width="100px"
            />
          </router-link>

          <v-img
            alt="Site Name"
            class="mt-1 hidden-sm-and-down"
            contain
            max-width="250"
            src="@/assets/text90814.png"
          />
        </div>

        <v-spacer></v-spacer>
        <v-btn disabled large class="mr-2 hidden-sm-and-down" text>
          <span>Projects</span>
        </v-btn>
        <!-- <v-btn to="/documentation" large class="mr-2 hidden-sm-and-down" text>
          <span>Information</span>
        </v-btn> -->
        <v-btn @click="applyNow" color="primary" elevation="0" raised large>
          <span>Apply Now</span>
        </v-btn>
      </v-container>
    </v-app-bar>

    <v-main class="pt-16 mt-2">
      <v-slide-y-reverse-transition mode="out-in">
        <router-view />
      </v-slide-y-reverse-transition>
      
    </v-main>
    <v-footer width="100%" dark padless>
      <v-card flat tile class="primary white--text text-center">
        <!-- <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text> -->
        <v-card-text
          ><h2 class="white--text">Toro Property Management</h2></v-card-text
        >

        <v-card-text class="white--text pt-0" style="font-family: quicksand">
          Striving to bring the best quality apartments in well advanced areas
          to help for progression in our societies, bring communities together
          in a successful way by building a progressive integrated lifestyle in
          the best way possible. We believe we have progressed passed the
          narrative of low-income bracket housing and brought in a solution to
          make housing not only affordable but to make it good to look at, good
          to live in and in the end bringing happiness and fulfilment to each
          and every tenant that decides to join us on this initiative. For us,
          for you, for tomorrow.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} —
          <strong>Toro Property Management</strong>
        </v-card-text>
      </v-card>
    </v-footer>
    <v-container>
          
    </v-container>
  </v-app>
</template>

<script>
import router from "./router/index";
export default {
  name: "App",

  data: () => ({
    loadingDialog: false,
    
    //
  }),
  methods: {
    applyNow() {
      (this.$store.state.houseChosen = false),
        (this.$store.state.chosenHouse = ""),
        router.push("/contactus");
    },
    resetIncome() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.$store.state.startDialog = true;
      this.$store.state.hidden=true;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

h1 {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}
h2 {
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
}
.rtl {
  direction: rtl;
}
</style>
