<template>
<div>
  <hello-world />
  <home-top/>
  
  </div>
</template>

<script>
import HomeTop from '@/components/HomeTop.vue'
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'HomeView',

    components: {
      HelloWorld,
      HomeTop
    },
    mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.$store.state.loadingDialog = false
    }, 500);
  },
  }
</script>
