<template>
  <div>
    <section
      style="background-size: cover"
      class="secondary hidden-sm-and-down"
    >
      <v-img max-height="75vh" src="@/assets/headhero.png"></v-img>
    </section>
    <section style="background-size: cover" class="secondary hidden-md-and-up">
      <v-img max-height="75vh" src="@/assets/mobileback.png"></v-img>
    </section>
    <section>
      <div
        class="white--text py-2"
        style="background-image: linear-gradient(#3e8ceb, #1b469c)"
      >
        <h1 class="text-center font-weight-black pt-2" style="font-size: 24px">
          The Garankuwa Project - Rental units now available
        </h1>
        <p class="text-center mx-2">
          Beautiful above standard affordable housing available at a fraction of
          the price, enquire now!
        </p>
      </div>
    </section>
    <section>
      <v-container>
        <v-row class="mb-0 pb-0">
          <v-col class="text-center mb-2 pb-0">
            <h2 class="font-weight-bold" style="font-size: 24px">
              Choose another income bracket
            </h2>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col class="text-center mt-0 pt-0">
            <v-btn
              min-width="296px"
              @click="resetIncome"
              class="red white--text"
              >Reset Bracket</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-carousel
        :cycle="cycle"
        height="100vh"
        v-model="carouselTop"
        mode="in-out"
      >
        <v-carousel-item
          src="@/assets/CarouselBack/One.png"
          v-if="chosenBracket == null"
        >
        </v-carousel-item>
        <!-- Tax bracket 1 -->
        <div v-if="chosenBracket == '1'">
          <v-carousel-item src="@/assets/CarouselBack/Two.png" eager
            ><v-container fill-height fluid>
              <v-row fill-height justify="center" align="center">
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                        ><h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>35&#13217; One Bedroom Apartments</h1>
                        <h2>Beautiful one bedroom apartment with a patio</h2>
                        <p class="mt-2">
                          Open plan kitchen and living area with a separate
                          bathroom and bedroom with generous space also
                          including a patio for a great view of the surroundings
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R940</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseTwo" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseTwoInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/35m2 One Bedroom.png"
                  >
                  </v-img>
                </v-col> </v-row></v-container
          ></v-carousel-item>
          <v-carousel-item eager src="@/assets/CarouselBack/One.png">
            <v-container fill-height fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                      >
                        <h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>30&#13217; Bachelor Apartments</h1>
                        <h2>Open plan apartment great for a start</h2>
                        <p class="mt-2">
                          Kitchen, bathroom, open plan bedroom and lounge with a
                          small patio
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R940</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseOne" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseOneInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/30m3 Bachelor.png"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </div>

        <!-- Tax bracket 2 -->
        <div v-if="chosenBracket == '2'">
          <v-carousel-item src="@/assets/CarouselBack/Two.png" eager
            ><v-container fill-height fluid>
              <v-row fill-height justify="center" align="center">
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                        ><h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>35&#13217; One Bedroom Apartments</h1>
                        <h2>Beautiful one bedroom apartment with a patio</h2>
                        <p class="mt-2">
                          Open plan kitchen and living area with a separate
                          bathroom and bedroom with generous space also
                          including a patio for a great view of the surroundings
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R1450</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseTwo" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseTwoInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/35m2 One Bedroom.png"
                  >
                  </v-img>
                </v-col> </v-row></v-container
          ></v-carousel-item>
          <v-carousel-item src="@/assets/CarouselBack/Three.png" eager
            ><v-container fill-height fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                        ><h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>40&#13217; Two Bedroom Apartments</h1>
                        <h2>
                          Spacious two bedroom apartment for the whole family
                        </h2>
                        <p class="mt-2">
                          Open plan well-sized kitchen with living area with two
                          generously sized bedrooms capable of holding a family.
                          Patio is also a given with gorgeous views of the
                          surrounding.
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R1900</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseThree" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseThreeInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/40m2 Two Bedroom.png"
                  >
                  </v-img>
                </v-col> </v-row></v-container
          ></v-carousel-item>
        </div>
        <!-- Tax Bracket 3 -->
        <div v-if="chosenBracket == '3'">
          <v-carousel-item src="@/assets/CarouselBack/Two.png" eager
            ><v-container fill-height fluid>
              <v-row fill-height justify="center" align="center">
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                        ><h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>35&#13217; One Bedroom Apartments</h1>
                        <h2>Beautiful one bedroom apartment with a patio</h2>
                        <p class="mt-2">
                          Open plan kitchen and living area with a separate
                          bathroom and bedroom with generous space also
                          including a patio for a great view of the surroundings
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R4470</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseTwo" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseTwoInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/35m2 One Bedroom.png"
                  >
                  </v-img>
                </v-col> </v-row></v-container
          ></v-carousel-item>
          <v-carousel-item eager src="@/assets/CarouselBack/One.png">
            <v-container fill-height fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                      >
                        <h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>30&#13217; Bachelor Apartments</h1>
                        <h2>Open plan apartment great for a start</h2>
                        <p class="mt-2">
                          Kitchen, bathroom, open plan bedroom and lounge with a
                          small patio
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R2880</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseOne" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseOneInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/30m3 Bachelor.png"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-carousel-item>
        </div>
        <!-- Tax bracket 4 -->
        <div v-if="chosenBracket == '4'">
          <v-carousel-item src="@/assets/CarouselBack/Two.png" eager
            ><v-container fill-height fluid>
              <v-row fill-height justify="center" align="center">
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                        ><h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>35&#13217; One Bedroom Apartments</h1>
                        <h2>Beautiful one bedroom apartment with a patio</h2>
                        <p class="mt-2">
                          Open plan kitchen and living area with a separate
                          bathroom and bedroom with generous space also
                          including a patio for a great view of the surroundings
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R4470</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseTwo" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseTwoInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/35m2 One Bedroom.png"
                  >
                  </v-img>
                </v-col> </v-row></v-container
          ></v-carousel-item>
        </div>
        <!-- Tax bracket 5 -->
        <div v-if="chosenBracket == '5'">
          <v-carousel-item src="@/assets/CarouselBack/Two.png" eager
            ><v-container fill-height fluid>
              <v-row fill-height justify="center" align="center">
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                        ><h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>35&#13217; One Bedroom Apartments</h1>
                        <h2>Beautiful one bedroom apartment with a patio</h2>
                        <p class="mt-2">
                          Open plan kitchen and living area with a separate
                          bathroom and bedroom with generous space also
                          including a patio for a great view of the surroundings
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R4470</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseTwo" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseTwoInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/35m2 One Bedroom.png"
                  >
                  </v-img>
                </v-col> </v-row></v-container
          ></v-carousel-item>
          <v-carousel-item src="@/assets/CarouselBack/Three.png" eager
            ><v-container fill-height fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-container fill-height fluid>
                    <v-row justify="center" align="center">
                      <v-col
                        class="ml-md-16 white--text text-center"
                        style="
                          border-radius: 8px;
                          background-color: rgb(0, 0, 0, 0.75);
                        "
                        ><h2
                          class="white black--text my-4"
                          style="border-radius: 12px"
                        >
                          YOU QUALIFY FOR
                        </h2>
                        <h1>40&#13217; Two Bedroom Apartments</h1>
                        <h2>
                          Spacious two bedroom apartment for the whole family
                        </h2>
                        <p class="mt-2">
                          Open plan well-sized kitchen with living area with two
                          generously sized bedrooms capable of holding a family.
                          Patio is also a given with gorgeous views of the
                          surrounding.
                        </p>
                        <v-divider class="white"></v-divider>
                        <h1>From R5365</h1>
                        <v-divider class="white mb-6"></v-divider>
                        <v-row justify="center" class="my-4">
                          <v-btn @click="houseThree" color="secondary"
                            >Apply Now</v-btn
                          >
                          <v-btn
                            @click="houseThreeInfo"
                            color="white"
                            class="ml-2"
                            >More information</v-btn
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="12" md="6">
                  <v-img
                    eager
                    class="mx-auto"
                    contain
                    height="100%"
                    src="@/assets/Houses/40m2 Two Bedroom.png"
                  >
                  </v-img>
                </v-col> </v-row></v-container
          ></v-carousel-item>
        </div>
      </v-carousel>
    </section>
    <!-- Choices of houses -->
    <section>
      <v-container>
        <v-row class="mt-6">
          <v-col>
            <h1 class="text-center red--text" style="font-size: 32px">
              Under your payment bracket you qualify for the following house/s
            </h1>
          </v-col>
        </v-row>

        <!-- <div >
          <v-row class="my-4" justify="center" align="center">
            <v-col cols="12" md="4">
              <v-card>
                <v-card-title><h1>30m2 Bachelor Apartments</h1></v-card-title>
                <v-card-text>
                  <h2>Open plan apartment great for a start</h2>
                  <p class="mt-2">
                    Kitchen, bathroom, open plan bedroom and lounge with a small
                    patio
                  </p>
                  <v-divider></v-divider>
                  <h1 class="my-2">From R2880</h1>
                  <v-divider></v-divider>
                  <div class="mt-4">
                    <v-btn @click="houseOne" color="secondary">Apply Now</v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card>
                <v-card-title><h1>35m2 Bachelor Apartments</h1></v-card-title>
                <v-card-text>
                  <h2>Beautiful one bedroom apartment with a patio</h2>
                  <p class="mt-2">
                    Open plan kitchen and living area with a separate bathroom
                    and bedroom with generous space also including a patio for a
                    great view of the surroundings
                  </p>
                  <v-divider></v-divider>
                  <h1 class="my-2">From R4470</h1>
                  <v-divider></v-divider>
                  <div class="mt-4">
                    <v-btn @click="houseTwo" color="secondary">Apply Now</v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card>
                <v-card-title><h1>40m2 Bachelor Apartments</h1></v-card-title>
                <v-card-text>
                  <h2>Spacious two bedroom apartment for the whole family</h2>
                  <p class="mt-2">
                    Open plan well-sized kitchen with living area with two
                    generously sized bedrooms capable of holding a family. Patio
                    is also a given with gorgeous views of the surrounding.
                  </p>
                  <v-divider></v-divider>
                  <h1 class="my-2">From R5365</h1>
                  <v-divider></v-divider>
                  <div class="mt-4">
                    <v-btn @click="houseThree" color="secondary"
                      >Apply Now</v-btn
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div> -->
        <!-- Tax bracket 1 -->
        <v-fade-transition>
          <div v-if="chosenBracket == '1'">
            <v-row class="my-4" justify="center" align="center">
              <v-col cols="12" md="4">
                <v-card>
                  <!-- <v-carousel
                  :continuous="false"
                  :cycle="cycle"
                  :show-arrows="true"
                  hide-delimiter-background
                  delimiter-icon="mdi-minus"
                  height="300"
                >
                  <v-carousel-item v-for="(slide, i) in slides" :key="i">
                    <v-sheet :color="colors[i]" height="100%" tile>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="text-h2">{{ slide }} Slide</div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel> -->
                  <v-card-title style="word-break: break-word"
                    ><h1>30&#13217; Bachelor Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Open plan apartment great for a start</h2>
                    <p class="mt-2">
                      Kitchen, bathroom, open plan bedroom and lounge with a
                      small patio
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R940</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseOne" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title style="word-break: break-word"
                    ><h1>35&#13217; One Bedroom Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Beautiful one bedroom apartment with a patio</h2>
                    <p class="mt-2">
                      Open plan kitchen and living area with a separate bathroom
                      and bedroom with generous space also including a patio for
                      a great view of the surroundings
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R940</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseTwo" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <!-- Tax bracket 2 -->
          <div v-if="chosenBracket == '2'">
            <v-row class="my-4" justify="center" align="center">
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title style="word-break: break-word"
                    ><h1>35&#13217; Bachelor Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Beautiful one bedroom apartment with a patio</h2>
                    <p class="mt-2">
                      Open plan kitchen and living area with a separate bathroom
                      and bedroom with generous space also including a patio for
                      a great view of the surroundings
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R1450</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseTwo" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <!-- Tax bracket 3 -->
          <div v-if="chosenBracket == '3'">
            <v-row class="my-4" justify="center" align="center">
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title style="word-break: break-word"
                    ><h1>35&#13217; One Bedroom Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Beautiful one bedroom apartment with a patio</h2>
                    <p class="mt-2">
                      Open plan kitchen and living area with a separate bathroom
                      and bedroom with generous space also including a patio for
                      a great view of the surroundings
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R4470</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseTwo" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title style="word-break: break-word"
                    ><h1>30&#13217; Bachelor Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Open plan apartment great for a start</h2>
                    <p class="mt-2">
                      Kitchen, bathroom, open plan bedroom and lounge with a
                      small patio
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R2880</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseTwo" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <!-- Tax Bracket 4 -->
          <div v-if="chosenBracket == '4'">
            <v-row class="my-4" justify="center" align="center">
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title style="word-break: break-word"
                    ><h1>35&#13217; One Bedroom Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Beautiful one bedroom apartment with a patio</h2>
                    <p class="mt-2">
                      Open plan kitchen and living area with a separate bathroom
                      and bedroom with generous space also including a patio for
                      a great view of the surroundings
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R4470</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseTwo" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <!-- Tax Bracket 5 -->
          <div v-if="chosenBracket == '5'">
            <v-row class="my-4" justify="center" align="center">
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title style="word-break: break-word"
                    ><h1>35&#13217; One Bedroom Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Beautiful one bedroom apartment with a patio</h2>
                    <p class="mt-2">
                      Open plan kitchen and living area with a separate bathroom
                      and bedroom with generous space also including a patio for
                      a great view of the surroundings
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R4470</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseTwo" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="4">
                <v-card>
                  <v-card-title style="word-break: break-word"
                    ><h1>40&#13217; Two Bedroom Apartments</h1></v-card-title
                  >
                  <v-card-text>
                    <h2>Grand design for the whole family!</h2>
                    <p class="mt-2">
                      Large 2 bedroom spacious living space to let you live in
                      luxury with a large open plan kitchen and living room and
                      a patio/balcony to enjoy the sun on a warm summer day
                    </p>
                    <v-divider></v-divider>
                    <h1 class="my-2">From R5365</h1>
                    <v-divider></v-divider>
                    <div class="mt-4">
                      <v-btn @click="houseTwo" color="secondary"
                        >Apply Now</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-fade-transition>
      </v-container>
    </section>
    <section>
      <div class="secondary">
        <v-container class="py-8" fill-height fluid>
          <v-row justify="center" align="center">
            <v-col class="justify-center pl-md-16" cols="12" md="6">
              <v-img
                max-height="480px"
                contain
                eager
                src="@/assets/image16720.png"
              ></v-img>
            </v-col>
            <v-col cols="12" md="6">
              <v-container class="white--text" style="max-width: 720px">
                <h1 style="font-size: 36px">
                  Services included in the secured area
                </h1>
                <h2>Community services will include :</h2>
                <ul class="my-4">
                  <li>24HR security - around the clock availability</li>
                  <li>Secured enclosed estate</li>
                  <li>Wifi access available immediately to all rentees</li>
                </ul>
                <h2 class="mt-2">Services coming in the near future :</h2>
                <ul class="my-4">
                  <li>Early childhood development center</li>
                  <li>Sports fields for sports events</li>
                  <li>Parks that the community can use at their leisure</li>
                </ul>
                <v-btn @click="areaInformation" color="primary"
                  >More Info</v-btn
                >
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>
    <section>
      <div>
        <v-container class="py-8" fill-height fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" md="6">
              <v-container style="max-width: 720px">
                <v-row align="center" justify="center">
                  <v-col>
                    <v-card class="ml-md-4" flat>
                      <h1 style="font-size: 36px">
                        Fibre and Wifi services available
                      </h1>
                      <div
                        class="pa-4 primary my-2 white--text"
                        style="border-radius: 8px"
                        v-if="this.chosenBracket == '5'"
                      >
                        <h2 class="font-weight-black white--text">
                          Free Wifi included in your package :
                        </h2>
                        <p class="white--text font-weight-black">
                          A fibre connected wifi connection will be added for
                          FREE to your package as your qualify for top tier
                          housing
                        </p>
                        <p class="white--text">
                          Free package for your qualification will be 20/20 Mbps
                          fibre connection
                        </p>
                        <p class="white--text">
                          If you want to upgrade please view below the other
                          fibre prices
                        </p>
                        <ul class="white--text">
                          <li>50/50 mbps fibre connection with wifi @ R499</li>
                          <li>
                            100/100 mbps fibre connection with wifi @ R699
                          </li>
                        </ul>
                      </div>
                      <div
                        class="pa-4 primary my-2"
                        style="border-radius: 8px"
                        v-if="this.chosenBracket == '4'"
                      >
                        <h2 class="font-weight-black white--text">
                          Free Wifi included in your package :
                        </h2>
                        <p class="white--text">
                          A fibre connected wifi connection will be added for
                          FREE to your package as your qualify for top tier
                          housing
                        </p>
                        <p>
                          Free package for your qualification will be 20/20 Mbps
                          fibre connection
                        </p>
                        <p>
                          If you want to upgrade please view below the other
                          fibre prices
                        </p>
                        <ul>
                          <li>50/50 mbps fibre connection with wifi @ R499</li>
                          <li>
                            100/100 mbps fibre connection with wifi @ R699
                          </li>
                        </ul>
                      </div>
                      <div
                        v-if="
                          this.chosenBracket == '1' ||
                          this.chosenBracket == '2' ||
                          this.chosenBracket == '3'
                        "
                      >
                        <h2>
                          Fibre with wifi connectiong available at the following
                          prices
                        </h2>
                        <ul class="my-4">
                          <li>20/20 mbps fibre connection with wifi @ R399</li>
                          <li>50/50 mbps fibre connection with wifi @ R499</li>
                          <li>
                            100/100 mbps fibre connection with wifi @ R699
                          </li>
                        </ul>
                      </div>
                      <v-btn @click="wifiInformation" color="primary"
                        >More Info</v-btn
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col class="justify-center pl-md-16" cols="12" md="6">
              <v-img
                class="mx-auto"
                max-height="480px"
                contain
                eager
                src="@/assets/wifi.png"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>
    <section class="primary">
      <div>
        <v-container class="py-8 white--text" fill-height fluid>
          <v-row justify="center" align="center">
            <v-col cols="12" md="6">
              <v-container style="max-width: 720px">
                <h1 style="font-size: 36px">
                  A massive project with 1592 rental units
                </h1>
                <h2>
                  Building a brand new community that will include multiple
                  ammenities and activities in the area
                </h2>
                <p class="mt-2">
                  This will include churches, sports fields, ample parking lots
                  and nearby shopping centres to make convenience a priority
                </p>
                <p class="font-weight-black">
                  Click below for a larger breakdown
                </p>
                <v-btn light to="/documentation" color="white">More Info</v-btn>
              </v-container>
            </v-col>
            <v-col class="justify-center pl-md-16" cols="12" md="6">
              <v-card class="py-4">
                <v-img
                  style="border-radius: 12px"
                  max-height="640px"
                  contain
                  eager
                  src="@/assets/HomePage/image18.png"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>
    <section class="secondary">
      <v-container fluid fill-height>
        <v-row justify="center" align="center">
          <v-col class="text-center white--text mb-6">
            <h1 style="font-size: 36px" class="mt-4">
              Click the following map to have a better view of the area
            </h1>
            <h2 class="mb-4">
              A lot of ammenities in the area and also central and near to
              Pretoria Main
            </h2>
            <a
              href="https://www.google.co.za/maps/place/25%C2%B034'28.8%22S+28%C2%B000'32.1%22E/@-25.6029302,28.0007522,19216m/data=!3m1!1e3!4m5!3m4!1s0x0:0xc20d89881224e65b!8m2!3d-25.5746778!4d28.0089056"
              target="_blank"
            >
              <v-img
                eager
                max-height="640px"
                contain
                src="@/assets/HomePage/image243.png"
              ></v-img>
            </a>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-layout row justify-center>
      <v-overlay
        opacity="1"
        v-model="this.$store.state.startDialog"
        max-width="500px"
      >
        <v-card light>
          <v-card-title class="primary white--text">
            <v-img
              class="shrink mr-2"
              contain
              src="@/assets/logo.png"
              transition="scale-transition"
              max-height="76px"
              max-width="100px"
            />
            <h2 style="word-break: break-word">
              Welcome to the Toro property managment website
            </h2>
          </v-card-title>
          <v-card-title>
            <h2 style="word-break: break-word">Complete the form to proceed</h2>
          </v-card-title>
          <v-card-subtitle class="mt-3">
            <p>Please indicate your income bracket below</p>
          </v-card-subtitle>
          <v-card-text>
            <v-select
              v-model="incomeBracket"
              :items="[
                'R0 - R3,500',
                'R3,501 - R6,700',
                'R6,701 - R11,300',
                'R11,301 - R15,000',
                'R15,001 - R22,000',
              ]"
              label="Insert Income"
              required
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-card-text
              v-if="incomeBracket == null"
              class="red white--text font-weight-black"
              >This form will appear every time you enter the
              homepage</v-card-text
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="incomeBracket != null"
              color="primary"
              elevation="0"
              @click="incomeChosen"
              >Confirm</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-layout>
    <v-overlay persistent opacity="1" v-model="loadingDialog">
      <v-row justify="center" align="center">
        <v-col>
          <v-progress-circular indeterminate></v-progress-circular>
        </v-col> </v-row
    ></v-overlay>
    <v-container>
      <v-tooltip color="secondary darken-2" left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="resetIncome"
            color="red"
            fab
            dark
            bottom
            right
            fixed
          >
            <v-icon color="white">mdi-restart</v-icon>
          </v-btn>
          
        </template>
        <span class="white--text">Reset Income</span>
      </v-tooltip>
    </v-container>
  </div>
</template>

<script>
import router from "../router/index";
export default {
  data: () => ({
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    loadingDialog: true,
    cycle: false,
    startDialog: true,
    incomeBracket: null,
    chosenBracket: null,
    carouselTop: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
  }),
  methods: {
    incomeChosen() {
      this.$store.state.startDialog = false;
      this.cycle = true;
      if (this.incomeBracket == "R0 - R3,500") {
        this.chosenBracket = "1";
        this.$store.state.chosenBracketStore = "1";
      } else if (this.incomeBracket == "R3,501 - R6,700") {
        this.chosenBracket = "2";
        this.$store.state.chosenBracketStore = "2";
      } else if (this.incomeBracket == "R6,701 - R11,300") {
        this.chosenBracket = "3";
        this.$store.state.chosenBracketStore = "3";
      } else if (this.incomeBracket == "R11,301 - R15,000") {
        this.chosenBracket = "4";
        this.$store.state.chosenBracketStore = "4";
      } else if (this.incomeBracket == "R15,001 - R22,000") {
        this.chosenBracket = "5";
        this.$store.state.chosenBracketStore = "5";
      }
      console.log(this.chosenBracket);
      console.log(this.$store.state.chosenBracketStore);
    },
    houseOne() {
      (this.$store.state.houseChosen = true),
        (this.$store.state.chosenHouse = "30m2 Open plan house");
      router.push("/contactus");
    },
    houseOneInfo() {
      (this.$store.state.houseChosen = true),
        (this.$store.state.chosenHouse = "30m2 Open plan house");
      router.push("/documentation");
    },
    houseTwo() {
      (this.$store.state.houseChosen = true),
        (this.$store.state.chosenHouse = "35m2 One Bedroom");
      router.push("/contactus");
    },
    houseTwoInfo() {
      (this.$store.state.houseChosen = true),
        (this.$store.state.chosenHouse = "35m2 Open plan house");
      router.push("/documentation");
    },
    houseThree() {
      (this.$store.state.houseChosen = true),
        (this.$store.state.chosenHouse = "40m2 Two Bedroom");
      router.push("/contactus");
    },
    houseThreeInfo() {
      (this.$store.state.houseChosen = true),
        (this.$store.state.chosenHouse = "40m2 Open plan house");
      router.push("/documentation");
    },
    wifiInformation() {
      this.$store.state.chosenHouse = "Wifi Information";
      router.push("/documentation");
    },
    areaInformation() {
      this.$store.state.chosenHouse = "Area Information";
      router.push("/documentation");
    },
    resetIncome() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      this.$store.state.startDialog = true;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadingDialog = true;
    if (this.$store.state.startDialog == false) {
      setTimeout(() => {
        this.loadingDialog = false;
      }, 1000);
    } else {
      setTimeout(() => {
        this.loadingDialog = false;
      }, 3500);
    }
  },

  beforeMount() {
    if (this.$store.state.chosenBracketStore == "1") {
      this.chosenBracket = "1";
    } else if (this.$store.state.chosenBracketStore == "2") {
      this.chosenBracket = "2";
    } else if (this.$store.state.chosenBracketStore == "3") {
      this.chosenBracket = "3";
    } else if (this.$store.state.chosenBracketStore == "4") {
      this.chosenBracket = "4";
    } else if (this.$store.state.chosenBracketStore == "5") {
      this.chosenBracket = "5";
    }
  },
};
</script>

<style>
#grad {
  background-image: linear-gradient(#3e8ceb, #1b469c);
}
carousel1 {
  background-image: src("@/assets/Carouselback/One.png");
}
</style>
