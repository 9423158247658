import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    houseChosen : false,
    chosenHouse : "",
    chosenIncome : false,
    startDialog : true,
    chosenBracketStore : "",
    loadingDialog : false,
    hidden: false,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
